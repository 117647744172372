import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import logoCTData from "./img/logo-ctdata.png"
import logoOSC from "./img/logo-osc.png"


export default ({ children }) => (
  <>
    <Helmet>
      <title>Connecticut Quasi-Public Organizations | CTData Collaborative</title>
      <meta name="description" content="This site provides access to checkbook and payroll information of quasi-public organizations in Connecticut. The data are updated yearly." />
      <meta name="title" content="Connecticut Quasi-Public Organizations | CTData Collaborative" />
      <link rel='shortcut icon' type='image/x-icon' href='/favicon.ico' />
    </Helmet>

    <header
      className="w-100 fw3 bb bw1 b--moon-gray poppins"
      style={{backgroundColor: '#2D3356'}}
    >

      <nav className="dt w-100 mw8 center ph2 pv3">
        <div className="dtc v-mid">
          <Link to="/" className="link white underline-hover">
            Connecticut Quasi-Public Organizations
          </Link>
        </div>
        <div className="dtc v-mid tr ph2">
          <Link to="/payroll" className="link white ph2 underline-hover">Payroll</Link> 
          <Link to="/checkbook" className="link white ph2 underline-hover">Checkbook</Link> 
        </div>
      </nav>

    </header>

    <article className="mw8 center poppins pa2 cf">
      <div className="bg-light-green pa3 mt3 br3">
        <b>July 2024 update</b>: This website has now been updated with the 2023 payroll and checkbook data.
        Data for the State Education Resource Center (SERC) will be added in the coming months.
        To share feedback or report any issues, please contact CTData Collaborative at <b>info@ctdata.org</b>.
      </div>
      {children}
    </article>

    <footer className="w-100 bg-near-white poppins mt5">
      <div className="mw8 center ph2 pv4 cf">

        <div className="fl pl0 w-100 w-two-thirds-l">
          <h5 className="f4 lh-copy mb0"> What are Quasi-Public Agencies </h5>
          <p className="measure">
            Quasi-public agencies are independent government corporations that
            are created through legislation to perform a particular service or set
            of public functions. Most quasi-public agencies depend primarily on
            sources of revenue associated with the services they provide.
            In Connecticut, quasi-public agencies are required to submit annual
            reports to the legislature, including a summary of their
            activities and financial information.
          </p>

          <p className="measure">
            In addition, quasi-public agencies are required to submit financial and
            personnel information to the comptroller's office which is uploaded to
            this application as part of the OpenConnecticut government transparency initiative.
          </p>

          <p className="measure">
            To learn more about a specific quasi-public agency, please visit their website.
          </p>

          <h5 className="f4 mb0"> About </h5>
          <p className="measure">
            The interface is developed and maintained
            by CTData Collaborative for the Connecticut Office of the State Comptroller.
          </p>

          <p>
            <a href="https://www.osc.ct.gov/openCT.html" className="dim">
              <img src={logoOSC} className="h4 dib v-mid mr3" alt="Office of the State Comptroller"/>
            </a>
            
            <a href="https://ctdata.org/" className="dim">
              <img src={logoCTData} className="h3 dib v-mid" alt="CTData Collaborative" />
            </a>
          </p> 

        </div>


        <div className="fl w-100 w-third-l lh-copy">

          <h5 className="mb0 f4">Relevant Links</h5>

          <ul className="list pl0">
            <li> <a href="https://data.ct.gov/" className="link black underline-hover">Connecticut Open Data</a> </li>
            <li> <a href="https://www.osc.ct.gov/openCT.html" className="link black underline-hover">Open Connecticut</a> </li>
            <li className="mt2"> <a href="https://openbudget.ct.gov" className="link black underline-hover">OpenBudget</a> </li>
            <li> <a href="https://opencheckbook.ct.gov/" className="link black underline-hover">OpenCheckbook</a> </li>
            <li> <a href="https://openpayroll.ct.gov" className="link black underline-hover">OpenPayroll</a> </li>
            <li> <a href="https://openpension.ct.gov" className="link black underline-hover">OpenPension</a> </li>
          </ul>

        </div>

      </div>
    </footer>
  </>
)